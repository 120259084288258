import { RemixBrowser } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { StrictMode, startTransition } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://3a81e236099d5070f2288b155718c9b5@o4508153593724928.ingest.de.sentry.io/4508153595625552",
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
